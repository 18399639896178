@import "../../../../assets/sass/functions/functions";

.experience-tour-choices {
  &__column-selection {
    justify-content: space-between;
    padding: 0;
    padding-top: scaleFromIpad(271);
    padding-bottom: scaleFromIpad(257);
  }

  .column-selection {
    &__title {
      font-size: scaleFromIpad(30) !important;
      font-weight: 400;
    }

    &__subtitle {
      font-size: scaleFromIpad(20) !important;
      font-weight: 700;
      padding: revert;
    }

    button {
      font-size: scaleFromIpad(20) !important;
      font-weight: 700;
      color: #424242 !important;
    }
  }
}
