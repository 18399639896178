@import "src/config/theme/theme";
@import "src/assets/sass/functions/functions";

.dashboard {
  $primary-color: get($theme, ("palette", "primary", "main"));

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 20% 1fr;
  grid-template-areas:
    "hearing-test  experience-tour product-catalog"
    "banner banner banner"
    "device-test schedule experience-tour-setup";
  height: 100%;

  &__banner {
    grid-area: banner;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    &-logo {
      padding: 2.5em 0;
      box-sizing: border-box;
    }
  }

  &__btn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border: none;
    border-radius: 0;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0;

    &[data-key="hearing-test"] {
      grid-area: hearing-test;
      background-image: url("../../../assets/img/backgrounds/dashboard_hearing_test.jpg");
    }

    &[data-key="experience-tour"] {
      grid-area: experience-tour;
      background-image: url("../../../assets/img/backgrounds/dashboard_experience_tour.jpg");
    }

    &[data-key="product-catalog"] {
      grid-area: product-catalog;
      background-image: url("../../../assets/img/backgrounds/dashboard_product_catalog.jpg");
    }

    &[data-key="schedule"] {
      grid-area: schedule;
      background-image: url("../../../assets/img/backgrounds/dashboard_schedule.jpg");
    }

    &[data-key="product-test"] {
      grid-area: device-test;
      background-image: url("../../../assets/img/backgrounds/dashboard_product_test.jpg");
    }

    &[data-key="experience-tour-setup"] {
      grid-area: experience-tour-setup;
      background-image: url("../../../assets/img/backgrounds/experience-tour_choices-video.jpg");
      background-position: bottom;
    }

    svg {
      height: 90px;
    }

    &-text {
      $size: scaleFromIpad(30);
      color: #fff;
      font-family: "Anton", serif;
      transition: transform 300ms ease;
      border: 0.1rem solid white;
      font-size: $size;
      line-height: $size;
      border-radius: $size;
      padding: 1rem 2.5rem;
      margin-bottom: 3.5rem;
    }

    &:hover {
      &::before {
        content: "";
        background-color: #c6c6c617;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
      }

      .dashboard__btn-text {
        transform: scale(1.05);
      }
    }
  }

  .modal-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    place-items: center;
    cursor: pointer;
    background-color: #00000040;
  }

  .confirm-modal {
    width: 33vw;
    background: white;
    border-radius: 10px;
  }
}
