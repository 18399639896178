@import "src/config/theme/theme";
@import "src/assets/sass/functions/functions";

.experience-tour-video-choices {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;

  .experience-tour-video-choices__col {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    button {
      position: absolute;
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.3rem;
    }

    .experience-tour-video-choices__text {
      text-align: center;
      color: #ffffff;
      font-weight: 500;
      font-size: 2rem;
      line-height: 3rem;
      position: absolute;
      top: calc(65% + 5rem);
      left: 50%;
      transform: translateX(-50%);
    }

    &:nth-child(1) {
      background-image: url("../../../../assets/img/backgrounds/experience-tour_choices_video.png");
      background-repeat: no-repeat;
      background-size: cover;
    }

    &:nth-child(2) {
      background-image: url("../../../../assets/img/backgrounds/experience-tour_choices_virtual-video.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
