@import "../../../../config/theme/theme";
@import "../../../../assets/sass/functions/functions";

.column-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24%;
  text-align: center;

  & > * {
    margin: unset;
  }

  &--has-background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    font-size: 2rem;
  }

  &__title,
  &__subtitle {
    padding-inline: 17%;
    color: #424242;
    margin-bottom: 2rem;
  }

  &__subtitle {
    font-size: 0.9rem;
    font-weight: 600;
  }

  &--inverted-color {
    .column-selection__title,
    .column-selection__subtitle {
      color: white;
    }
  }
}
