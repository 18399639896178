:root {
  --app-primary: #0164FF;
  --app-secondary: #003B9D;
  --app-info: #666;
  --app-error: #f44336;
  --app-dark: #424242;
  --app-gradient: linear-gradient(to right, #0164FF, #003B9D);
  --app-inverted-gradient: linear-gradient(to left, #0164FF, #003B9D);  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; /** no view w/ scroll */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.adm-picker-header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  place-items: center;
}

.adm-picker-header-button {
  font-weight: bold;
  color: var(--app-primary)
}

.adm-picker-header-title {
  flex: none;
}