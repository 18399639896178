@import "../../../../assets/sass/functions/functions";
@import "../../../../config/theme/theme";

.player {
  button {
    &,
    &:hover,
    &:focus {
      all: unset !important;
      cursor: pointer !important;
    }

    &:nth-child(2) {
      margin-right: 50px !important;
      margin-left: 50px !important;
    }
  }

  .active-player {
    .btn {
      &__back {
        line-height: 45px !important;
        min-width: 12rem !important;
        font-size: 1.5rem !important;
        border-radius: 10px !important;
        padding: 0.8rem 1rem !important;
        font-weight: 500 !important;
        color: white !important;
        cursor: pointer !important;
        text-align: center !important;
        vertical-align: middle !important;
        background: #ea4e3d !important;
      }
      &__next {
        line-height: 45px !important;
        min-width: 12rem !important;
        font-size: 1.5rem !important;
        border-radius: 10px !important;
        padding: 0.8rem 1rem !important;
        font-weight: 500 !important;
        color: white !important;
        cursor: pointer !important;
        text-align: center !important;
        vertical-align: middle !important;
        background: #67ce67 !important;
      }
    }
    button:nth-child(2) svg {
      $shadow-color: #1662ad;

      $shadow-spread: 30px;

      border-radius: 12px;
      box-shadow: 0 0 0 0 rgba($shadow-color, 0.5);
      animation: pulse 0.9s infinite;
      -webkit-animation: pulse 0.9s infinite;
      -moz-animation: pulse 0.9s infinite;

      &:hover {
        -webkit-animation: none;
      }

      @-webkit-keyframes pulse {
        70% {
          box-shadow: 0 0 0 $shadow-spread rgba($shadow-color, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($shadow-color, 0);
        }
      }

      @-moz-keyframes pulse {
        70% {
          box-shadow: 0 0 0 $shadow-spread rgba($shadow-color, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($shadow-color, 0);
        }
      }

      @keyframes pulse {
        70% {
          box-shadow: 0 0 0 $shadow-spread rgba($shadow-color, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($shadow-color, 0);
        }
      }
    }
  }
}
