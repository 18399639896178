@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

$primary: get($theme, ("palette", "primary", "main"));

.video__close-icon {
  position: absolute;
  top: 6vh;
  left: 4vw;
  z-index: 351483773;
  width: auto;
  height: 6vh;
  cursor: pointer;
}

.video-js {
  font-size: 10px;

  height: 100% !important;
}

.vjs-tech {
  height: 100% !important;
}

.video-js .vjs-picture-in-picture-control,
.pip-expanded, .pip-small {
  display: none;
}

.vjs-big-play-button {
  background-color: $primary !important;
  font-size: 3.5em !important;
  $big-play-size: 3em;
  line-height: $big-play-size !important;
  height: $big-play-size !important;
  width: $big-play-size !important;
  border: unset !important;
  border-radius: 15px !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: unset !important;

  .vjs-icon-placeholder::before {
    font-size: 1.5em !important;
  }
}

.vjs-control-bar {
  background: transparent !important;
  padding: 3rem;  
  align-items: center !important;
  height: revert !important;
}

.vjs-play-control {
  width: 4rem !important;
  height: 4rem !important;
  outline: 0;
  background: $primary !important;
  border-radius: 0.5em !important;

  .vjs-icon-placeholder {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    height: 100% !important;

    &::before {
      display: block;
      font-size: 4rem;
      line-height: 4rem;      
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.vjs-progress-control {
  .vjs-progress-holder {
    height: 0.7em !important;
    margin: 0 30px !important;
  }
}

.vjs-play-progress::before {
  font-size: 1.25em !important;
  top: -0.2em !important;
}

.vjs-volume-panel,
.vjs-remaining-time,
.vjs-fullscreen-control {
  display: none !important;
}
