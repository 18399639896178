@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

$primary-color: get($theme, ("palette", "primary", "main"));
$dark-color: get($theme, ("palette", "dark", "main"));
$border-width: scaleFromIpad(1);

.input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  &__label {
    font-size: scaleFromIpad(20);
    font-weight: 500;
    color: $dark-color;
    line-height: scaleFromIpad(45);
    margin-left: $border-width; // Align w/ input
  }

  .last-char-asterisk::after {
    content: " *";
    color: #0000ec;
  }

  &__input {
    height: scaleFromIpad(43);
    padding: $border-width 1rem; // Align w/ input
    font-size: scaleFromIpad(15);
    font-weight: 500;
    color: $dark-color;
    border-radius: scaleFromIpad(5);
    border: $border-width solid transparent;
    box-shadow: inset 0px 0px 4px 1px rgba(0, 0, 0, 0.15);
    transition: border 200ms ease-in-out;
    background: white;
    text-align: left;

    &::placeholder {
      color: lighten($dark-color, 10);
    }

    &:focus,
    &:focus-visible {
      outline: none;
      border-color: $primary-color;
    }

    /* Remove input number arrow for Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Remove input number arrow for Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &__errors {
    padding-left: $border-width; // Align w/ input
    margin-top: $border-width !important;
    position: absolute;
    bottom: scaleFromIpad(-35);
    font-size: scaleFromIpad(15);
  }

  &--has-errors {
    .input-wrapper__input {
      border-color: get($theme, ("palette", "error", "main"));
    }
  }
}
