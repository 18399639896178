@import "../../../config/theme/theme";
@import "../../../assets/sass/functions/functions";

.textarea-wrapper {
  $primary-color: get($theme, ("palette", "primary", "main"));
  $border-width: 2px;

  display: flex;
  flex-direction: column;
  //position: relative;

  &__label {
    font-size: 1.05rem;
    font-weight: 500;
    color: $primary-color;
    margin-bottom: 0.7rem;
    margin-left: $border-width; // Align w/ textarea
  }

  &__textarea {
    padding: 0.95rem 1rem; // Align w/ textarea
    font-size: 0.95rem;
    font-weight: 500;
    color: $primary-color;
    border-radius: 5px;
    border: $border-width solid transparent;
    box-shadow: 0 0 4px 1px #00000030 inset;
    transition: border 200ms ease-in-out;
    resize: none;

    &::placeholder {
      color: lighten($primary-color, 10);
    }

    &:focus,
    &:focus-visible {
      outline: none;
      border-color: $primary-color;
    }

    &--active-resize {
      resize: both;
    }
  }

  &__errors {
    padding-left: $border-width; // Align w/ textarea
    margin-top: $border-width !important;
    //margin-bottom: unset !important;
    //position: absolute;
    //bottom: -1rem;
  }

  &--has-errors {
    .textarea-wrapper__textarea {
      border-color: get($theme, ("palette", "error", "main"));
    }
  }
}
